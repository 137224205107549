import { AxiosMasterGet } from "../Axios";

export const SET_TITIP_CUSTOMER = "SET_TITIP_CUSTOMER";
export const GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER =
  "GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER";

export const setTitipCustomer = ({ customer }) => {
  return (dispatch) => {
    AxiosMasterGet("trx-customer/saldo-titip/" + customer)
      .then((res) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            titip_gr: res.data?.titip_gr || 0,
            titip_rp: res.data?.titip_rp || 0,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const setTitipSupplier = ({ supplier }) => {
  return (dispatch) => {
    AxiosMasterGet("trx-supplier/saldo-titip/" + supplier)
      .then((res) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            titip_gr: res.data?.titip_gr || 0,
            titip_rp: res.data?.titip_rp || 0,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: SET_TITIP_CUSTOMER,
          payload: {
            data: false,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getDataSelectedPembayaranCustomer = () => {
  var hasil = JSON.parse(localStorage.getItem("listHutangAll")) || [];
  return (dispatch) => {
    dispatch({
      type: GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER,
      payload: {
        data: hasil,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
        error: true,
      },
    });
  };
};
