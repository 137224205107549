import {
  EDIT_PROVINSI,
  GET_PROVINSI_LIST,
} from "../DataMaster/provinsi/redux/provinsiAction";
import { EDIT_KOTA, GET_KOTA_LIST } from "../DataMaster/kota/redux/kotaAction";
import { EDIT_AREA, GET_AREA_LIST } from "../DataMaster/area/redux/areaAction";
import {
  EDIT_REKENING_LIST,
  GET_REKENING_LIST,
} from "../DataMaster/rekening/redux/rekeningAction";
import {
  EDIT_LOKASI,
  GET_LOKASI_LIST,
  GET_LOKASI_MIX,
} from "../DataMaster/lokasi/redux/lokasiAction";
import {
  EDIT_SALESMAN,
  GET_SALESMAN_LIST,
} from "../DataMaster/sales/redux/salesmanAction";
import {
  EDIT_SUPPLIER,
  GET_LOKASI_PINDAH,
  GET_SUPPLIER_LIST,
} from "../DataMaster/supplier/redux/supplierAction";
import {
  EDIT_CUSTOMER,
  GET_ANAK_CUSTOMER,
  GET_CUSTOMER_INDUK_LIST,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_LIST,
  SET_CASH_TEMP,
  SET_CASH_USD_TEMP,
  SET_GIRO_TEMP,
  SET_SELECTED_BON,
  SET_TRANSFER_TEMP,
} from "../DataMaster/customer/redux/customerAction";
import { EDIT_KATEGORI, GET_KATEGORI_LIST } from "../actions/kategoriAction";
import {
  GET_HUTANG_SUPPLIER,
  GET_PEMBAYARANSUPPLIER_LIST,
  SET_24K_TEMP,
  SET_CIOK_TEMP,
  SET_RONGSOK_TEMP,
  SET_TOTAL_NETTO_SUPPLIER,
  SET_TOTAL_RP_SUPPLIER,
} from "../actions/pembayaranSupplierAction";
import { GET_SIDEBAR_UPDATE } from "../actions/sidebarAction";
import {
  EDIT_KADAR,
  GET_ALL_KADAR,
  GET_KADAR_LIST,
} from "../DataMaster/kadar/redux/kadarAction";
import {
  EDIT_ROSOK,
  GET_ROSOK_LIST,
} from "../DataMaster/ciok-rosok/redux/rosokAction";
import { GET_PARAMETER_TRANSAKSI } from "../DataMaster/parameter-transaksi/redux/parameterTransaksiAction";
import {
  EDIT_KELOMPOK,
  GET_KELOMPOK,
} from "../DataMaster/kelompok/redux/kelompokAction";
import {
  GET_KODE_AKSESORIS_LIST,
  GET_KODE_BARANG_CIOK,
  GET_KODE_BARANG_LIST,
  GET_KODE_BARANG_MASTER,
} from "../actions/kodeBarangAction";
import { GET_UUID } from "../actions/fakturAction";
import {
  GET_LIST_PIUTANG_CUSTOMER,
  TOTAL_BON,
} from "../actions/TrasferCustomerAction";
import {
  GET_LIST_UNVALID_TERIMA_BARANG,
  GET_RETURN_SUPPLIER_TEMP,
  GET_TERIMA_SUPPLIER_TEMP,
  SAVE_PREV_NO_TERIMA,
  SET_EDIT_PENERIMAAN_SUPP,
  SET_EDIT_TERIMA_SUPPLIER,
  SET_TOTAL_BRUTO_TERIMA_SUPPLIER,
  SET_TOTAL_GROSS_TERIMA_SUPPLIER,
  SET_TOTAL_NW_TERIMA_SUPPLIER,
  SET_TOTAL_ONGKIR_TERIMA_SUPPLIER,
  SET_TOTAL_PKG_TERIMA_SUPPLIER,
} from "../Transaksi/penerimaan-supplier/redux/terimaSupplierAction";
import { GET_TABLE_TOLAKAN_CUSTOMER } from "../actions/tolakCustomer";
import { GET_DATA_USER, GET_LIST_DATA_USER } from "../actions/userAction";
import { EDIT_VALIDASI } from "../actions/paremeterAction";
import { GET_LIST_CETAK_ULANG } from "../actions/cetakUlangAction";
import {
  EDIT_PINDAH_BARANG,
  GET_DATA_EDIT_PINDAH_BARANG,
  GET_DATA_PINDAH_BARANG,
} from "../actions/pindahBarangAction";
import { GET_DATA_MUTASI_BARANG } from "../Stocking/mutasi-barang/redux/mutasiBarangAction";
import { GET_DATA_MUTASI_CIOK } from "../actions/mutasiCiokAction";
import { GET_DATA_KIRIM_MASAK } from "../actions/kirimMasakAction";
import { GET_DATA_KIRIM_BAHAN } from "../actions/kirimBahanAction";
import {
  GET_DATA_RETURN_SUPPLIER,
  GET_DATA_TOLAKAN_HUTANG_SUPPLIER,
  GET_DATA_TOLAKAN_PEMBAYARAN_CUSTOMER,
  GET_DATA_TOLAKAN_RETURN_CUSTOMER,
  GET_DATA_TOLAKAN_RETURN_SUPPLIER,
  GET_UNVALID_RETUR_SUPPLIER,
} from "../Transaksi/retur-supplier/redux/returnSupplier";
import { GET_DATA_PENGAMBILAN_BARCODE } from "../actions/pengambilanBarcodeAction";
import {
  GET_CLOSE_PENGAMBILAN_BARCODE_SELECTED,
  GET_DATA_CLOSE_PENGAMBILAN_BARCODE,
  GET_LIST_CLOSE_BARCODE,
} from "../actions/closeBarcodeAction";
import {
  GET_DATA_TIMBANG_BARANG,
  GET_DATA_TIMBANG_STOCK,
} from "../actions/timbangBarangAction";
import {
  CHANGE_EDIT_BON_PENJUALAN,
  GET_DATA_TRANSAKSI_PENJUALAN,
  GET_DATA_TRANSKASI_REPARASI,
  GET_UNVALID_PENJUALAN,
  SAVE_PREV_NO_PENJUALAN,
  SET_DATA_PO,
  SET_EDIT_PENJUALAN,
  SET_EDIT_TRANSAKSI_PENJUALAN,
} from "../Transaksi/penjualan-customer/redux/transaksiPenjualanAction";
import {
  CHANGE_EDIT_BON_RETURN_PENJUALAN,
  GET_DATA_RETURN_PENJUALAN,
  GET_DATA_RETURN_PENJUALAN_NON_TOKO,
  GET_TOTAL_NW,
  GET_UNVALID_RETUR_CUSTOMER,
  SAVE_PREV_NO_RETURN_PENJUALAN,
  SET_EDIT_RETUR_PENJUALAN,
  SET_EDIT_TRANSAKSI_RETURN_PENJUALAN,
} from "../Transaksi/retur-customer/redux/returnPenjualanAction";
import {
  GET_SELISIH_TOTALAN,
  GET_STATUS_TOTALAN,
  GET_TABEL_TOTALAN_BARCODE,
  GET_TABEL_TOTALAN_TIMBANGAN,
} from "../actions/TotalanTimbanganAction";
import { GET_LIST_TUKANG } from "../actions/listTukang";
import { GET_TABLE_TERIMA_MASAK } from "../actions/TerimaMasakAction";
import {
  GET_VALIDASI_BARANG_KIRIM_MASAK,
  GET_VALIDASI_BARANG_MUTASI_BARANG,
  GET_VALIDASI_BARANG_MUTASI_LK,
  GET_VALIDASI_BARANG_PINDAH_BARANG,
  GET_VALIDASI_BARANG_TERIMA_MASAK,
  GET_VALIDASI_KIRIM_STOCK,
} from "../actions/validasiBarangAction";
import {
  GET_DATA_SELECTED_VALIDASI_SUPPLIER,
  GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER,
  GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER,
  GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER,
  GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER,
  GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER,
  GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER,
} from "../actions/validasiSupplierAction";
import {
  GET_VALIDASI_CUSTOMER_CORRECTION_NOTE,
  GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM,
  GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON,
  GET_VALIDASI_CUSTOMER_PEMBELIAN_LM,
  GET_VALIDASI_CUSTOMER_PENJUALAN,
  GET_VALIDASI_CUSTOMER_PENJUALAN_24K,
  GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN,
  GET_VALIDASI_CUSTOMER_TITIP_GR_CUSTOMER,
  GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER,
  GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE,
} from "../actions/validasiCustomerAction";
import {
  GET_DATA_LIST_PEMBELIAN_LM,
  GET_DATA_LIST_PENJUALAN_LM,
  GET_DATA_LIST_SUM_PEMBAYARAN_LM,
  GET_DATA_SALDO_AWAL_PIUTANG_CUSTOMER,
  GET_DATA_SALDO_AWAL_STOK,
  GET_DATA_TRANSAKSAI_PENJUALAN_LM,
} from "../actions/transaksuPenjualanLMAction";
import { GET_MENU_LIST } from "../actions/getMenu";
import {
  GET_DATA_REKAP_BON_CUSTOMER,
  SET_SELECTED_REKAP_BON,
} from "../actions/rekapBonAction";
import {
  GET_DATA_CLOSE_TRANSAKSI_PENJUALAN_SELECTED,
  GET_DATA_CLOST_TRANSAKSI_PENJUALAN,
} from "../actions/closeTransaksiPenjualanAciton";
import { FINISH_SEND, PROGRESS_SEND } from "../actions/LogicAction";
import { SET_LOGIN } from "../actions/titleAction";
import {
  HIDE_GUIDE_TOUR,
  SET_TIPE_BAYAR_MODAL,
  SHOW_GUIDE_TOUR,
} from "../actions/ModalAction";
import { GET_IS_LOGIN } from "../actions/loginAction";
import {
  GET_DATA_SELECTED_VALIDASI_TRANSFER,
  GET_LIST_VALIDASI_TRANSFER_CUSTOMER,
} from "../actions/validasiTransferCustomer";
import {
  GET_DATA_SELECTED_VALIDASI_GIRO,
  GET_LIST_VALIDASI_PEMBBAYARAN_GIRO,
} from "../actions/validasiPembayaranGiro";
import {
  GET_LIST_TIMBANGAN,
  GET_LIST_TIMBANGAN_SELECTED,
} from "../actions/closeTimbangan";
import {
  GET_LIST_PACKING,
  GET_LIST_PACKING_PENJUALAN,
  GET_LIST_PO,
  GET_LIST_PO_PACKING,
  GET_LIST_PO_PENJUALAN,
  GET_VALIDASI_PO,
  GET_VALIDASI_PO_SELECTED,
} from "../actions/validasiPOAction";
import {
  GET_DATA_BARANG_PACKING,
  GET_DATA_BARANG_PACKING_M,
  GET_DATA_BARANG_PACKING_S,
  SET_DATA_GAMBAR_L,
  SET_DATA_GAMBAR_M,
  SET_DATA_GAMBAR_S,
} from "../actions/PackingListAction";
import { GET_CAMERA_URI } from "../actions/cameraAction";
import {
  GET_KELOMPOK_HARGA,
  SAVE_DUPLIKAT_DATA,
} from "../actions/kelompok_harga";
import {
  EDIT_MODEL,
  GET_ALL_GROUP,
  GET_BARANG_SET_OPEN,
  GET_DATA_BARANG_SET,
} from "../DataMaster/barang/redux/barangAction";
import {
  GET_SALDO_BARANG,
  GET_SALDO_BARANG_REKAP,
  KIRIM_STOCK_PUSAT,
  VALIDATE_OUTSTAND,
} from "../actions/dashboardAction";
import {
  GET_LIST_VALIDASI_REPARASI,
  GET_LIST_VALIDASI_REPARASI_SELECTED,
} from "../actions/validasiReparasi";
import { GET_LIST_SELESAI_REPARASI } from "../actions/selesaiReparasi";
import { GET_LIST_SERAH_REPARASI } from "../actions/serahReparasi";
import { GET_LIST_TIMBANGAN_STOCK } from "../actions/TotalanStock";
import { GET_JENIS_LABEL } from "../actions/pengaturan";
import { GET_DATA_STOCK } from "../actions/TotalanTimbanganStock";
import {
  EDIT_BANK_LIST,
  GET_BANK_LIST,
} from "../DataMaster/bank/redux/bankAction";
import { GET_LIST_PEMGAMBILAN_CIOK } from "../actions/pengambilanCiokAction";
import {
  EDIT_REPARASI,
  GET_LIST_REPARASI_BARANG,
  GET_REPARASI_LIST,
} from "../actions/reparasiAction";
import {
  GET_PARAMETER_REPARASI,
  SET_DUPLIKAT_REPARASI,
} from "../actions/parameterReparasiAction";
import {
  GET_ALL_CABANG_SUPPLIER,
  SET_EDIT_CABANG_SUPPLIER,
} from "../DataMaster/cabang-supplier/redux/cabangSupplierAction";
import { GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER } from "../actions/pembayaranCustomerAction";

const initialState = {
  showTourGuide: false,
  noFaktur: false,
  tipeBayarModal: false,
  listDataUser: false,
  username: false,
  level: false,
  listMenu: false,
  provinsi: [],
  editProvinsi: false,
  kota: [],
  editKota: false,
  area: [],
  editArea: false,
  rekening: [],
  editDataRekening: false,
  bank: [],
  editDataBank: false,
  lokasi: [],
  editLokasi: false,
  salesman: [],
  editSalesman: false,
  supplier: [],
  editSupplier: false,
  customer: [],
  customerInfo: [],
  editCustomer: false,
  kategori: [],
  editKategori: false,
  kodeBarang: [],
  editKodeBarang: false,
  pembayaranSupplier: [],
  namaSupplier: false,
  kadar: [],
  editKadar: false,
  listValidasiBarang: false,
  listValidasiSupplier: false,
  listDataSelectedValidasiSupplier: false,
  listDataSelectedPembayaranCustomer: false,
  listValidasiCustomer: false,
  listEditPindahBarang: false,
  listDataTransaksiPenjualanLM: false,
  jumlahTransaksiPenjualanLM: false,
  totalTransaksiPenjualanLM: false,
  listTableTotalanBarcode: false,
  listCloseTransaksiPenjualan: false,
  listCloseTransaksiPenjualanSelected: false,
  sumTotalBayar: 0,
  rosok: [],
  editRosok: false,
  pindahBarang: false,
  editPindahBarang: false,
  parameterTransaksi: [],
  penjualan24K: [],
  terimaSupplierTemp: false,
  returnSupplierTemp: false,
  listCetak: false,
  mutasiBarang: false,
  mutasiCiok: false,
  kirimMasak: false,
  kirimBahan: false,
  listTukang: false,
  returnSupplier: false,
  pengambilanBarcode: false,
  closePengambilanBarcode: false,
  timbangBarang: false,
  transaksiPenjualan: false,
  returnPenjualan: false,
  totalanTimbangan: false,
  listTerimaMasak: false,
  listDataTablePenjualanLM: false,
  listDataTablePembelianLM: false,
  dataSaldoAwalPiutangCustomer: false,
  dataSaldoAwalStok: false,
  listDataRekapBon: false,
  totalNW: 0,
  totalBruto: 0,
  totalPkg: 0,
  totalGross: 0,
  totalOngkir: 0,
  sumTunai: 0,
  sumTunaiNetto: 100,
  sumNetto: 0,
  sumTransfer: 0,
  sumTransferNetto: 0,
  sumGiro: 0,
  sumGiroNetto: 0,
  tampungCiok: false,
  tampung24k: false,
  tampungRongsok: false,
  tampungCash: false,
  tampungCashUSD: false,
  tampungTransfer: false,
  tampungGiro: false,
  totalCiok: 0,
  total24k: 0,
  totalBrutoRongsok: 0,
  totalBruto24k: 0,
  totalBrutoCiok: 0,
  totalRongsok: 0,
  editUser: false,
  editParameterValidasi: false,
  tabelTolakanCustomer: false,
  Kelompok: [],
  totalBon: false,
  selectedBon: false,
  dataHutangSupplier: [],
  dataLokasiPindah: [],
  listValidasiTransfer: [],
  listValidasiTransferSelected: [],
  listValidasiGiro: [],
  listValidasiGiroSelected: [],
  listCloseBarcode: [],
  listCloseBarcodeSelected: [],
  no_barcode: "",
  listCloseTimbangan: [],
  listCloseTimbanganSelected: [],
  listTolakanHutangSupplier: [],
  listTolakanReturnSupplier: [],
  listTolakanPembayaranCustomer: [],
  listTolakanReturnCustomer: [],
  listPiutangCustomer: [],
  listTransferBalance: [],
  no_timbangan: "",
  status_totalan: [],
  selisih_totalan: [],
  validasiPO: [],
  validasiPOSelected: [],
  validasiKirimStock: [],
  listPO: [],
  packingList: [],
  listPOPacking: [],
  listPackingS: [],
  listPackingM: [],
  listPacking: [],
  cameraURI: [],
  listGambarS: [],
  listGambarM: [],
  listGambarL: [],
  kelompokHarga: [],
  listBarangSet: [],
  BarangSet: [],
  error: false,
  sidebar: false,
  sweetProvinsi: "",
  editKelompok: [],
  dataPO: [],
  SaldoBarang: [],
  ValidateOutstand: [],
  listReparasi: [],
  ListSelesaiReparasi: [],
  ListSerahReparasi: [],
  ListTimbangStock: [],
  ListKirimStockPusat: [],
  ListReturnPenjualanNonToko: [],
  totalTimbangStock: 0,
  ListAksesoris: [],
  EditModel: "",
  ListModelMaster: [],
  total_netto_supplier: 0,
  total_rp_supplier: 0,
  jenis_label: "",
  data_stock: [],
  customerInduk: [],
  barangciok: [],
  listPengambilanCiok: [],
  reparasi: [],
  editReparasi: [],
  reparasi_barang: [],
  anak_customer: [],
  duplikat_data: [],
  duplikat_reparasi: [],
  listGroup: [],
  onSend: false,
  dataCabangSupplier: [],
  editCabangSupplier: [],
  listUnvalidTerimaBarang: [],
  editTerimaSupplier: [],
  prev_no_terima: "-",
  prev_no_penjualan: "-",
  prev_no_return_penjualan: "-",
  listUnvalidReturSupplier: [],
  listUnvalidPenjualan: [],
  editPenjualanData: [],
  isEditBon: false,
  isEditBoReturn: false,
  selectedRekapBon: [],
  saldoBarangRekap: [],
  editPenjualan24K: [],
  alert:
    "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
  isEditTerimaSupp: false,
  isEditTransaksiPenjualan: false,
  isEditTransaksiReturnPenjualan: false,
  listUnvalidReturCustomer: [],
};

const provinsi = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIDEBAR_UPDATE:
      return {
        ...state,
        sidebar: action.payload.data,
      };
    case GET_PROVINSI_LIST:
      return {
        ...state,
        provinsi: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_PROVINSI:
      return {
        ...state,
        editProvinsi: action.payload.data,
      };
    case GET_KOTA_LIST:
      return {
        ...state,
        kota: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_KOTA:
      return {
        ...state,
        editKota: action.payload,
      };
    case GET_AREA_LIST:
      return {
        ...state,
        area: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_AREA:
      return {
        ...state,
        editArea: action.payload,
      };
    case GET_REKENING_LIST:
      return {
        ...state,
        rekening: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_REKENING_LIST:
      return {
        ...state,
        editDataRekening: action.payload,
      };
    case GET_LOKASI_LIST:
      return {
        ...state,
        lokasi: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_LOKASI:
      return {
        ...state,
        editLokasi: action.payload,
      };
    case GET_SALESMAN_LIST:
      return {
        ...state,
        salesman: action.payload.data,
        alert: action.payload.alert,
        namaSupplier: action.payload.data.namaSupplier,
        error: action.payload.error,
      };
    case EDIT_SALESMAN:
      return {
        ...state,
        editSalesman: action.payload,
      };
    case GET_SUPPLIER_LIST:
      return {
        ...state,
        supplier: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_SUPPLIER:
      return {
        ...state,
        editSupplier: action.payload,
      };
    case GET_CUSTOMER_LIST:
      return {
        ...state,
        customer: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_CUSTOMER:
      return {
        ...state,
        editCustomer: action.payload,
      };
    case GET_KATEGORI_LIST:
      return {
        ...state,
        kategori: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_KATEGORI:
      return {
        ...state,
        editKategori: action.payload,
      };
    case GET_PEMBAYARANSUPPLIER_LIST:
      return {
        ...state,
        pembayaranSupplier: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_KADAR_LIST:
      return {
        ...state,
        kadar: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_KADAR:
      return {
        ...state,
        editKadar: action.payload,
      };
    case GET_ROSOK_LIST:
      return {
        ...state,
        rosok: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case EDIT_ROSOK:
      return {
        ...state,
        editRosok: action.payload,
      };
    case GET_PARAMETER_TRANSAKSI:
      return {
        ...state,
        parameterTransaksi: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_KELOMPOK:
      return {
        ...state,
        Kelompok: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_KODE_BARANG_LIST:
      return {
        ...state,
        kodeBarang: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_UUID:
      return {
        ...state,
        noFaktur: action.payload.data,
      };
    case GET_TERIMA_SUPPLIER_TEMP:
      return {
        ...state,
        terimaSupplierTemp: action.payload.data,
        totalNW: action.payload.totalNW,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_RETURN_SUPPLIER_TEMP:
      return {
        ...state,
        returnSupplierTemp: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TOTAL_NW_TERIMA_SUPPLIER:
      return {
        ...state,
        totalNW: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TOTAL_BRUTO_TERIMA_SUPPLIER:
      return {
        ...state,
        totalBruto: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TOTAL_PKG_TERIMA_SUPPLIER:
      return {
        ...state,
        totalPkg: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TOTAL_GROSS_TERIMA_SUPPLIER:
      return {
        ...state,
        totalGross: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TOTAL_ONGKIR_TERIMA_SUPPLIER:
      return {
        ...state,
        totalOngkir: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_CIOK_TEMP:
      return {
        ...state,
        tampungCiok: action.payload.data,
        totalCiok: action.payload.totalNetto,
        totalBrutoCiok: action.payload.totalBruto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_24K_TEMP:
      return {
        ...state,
        tampung24k: action.payload.data,
        total24k: action.payload.totalNetto,
        totalBruto24k: action.payload.totalBruto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_RONGSOK_TEMP:
      return {
        ...state,
        tampungRongsok: action.payload.data,
        totalRongsok: action.payload.totalNetto,
        totalBrutoRongsok: action.payload.totalBruto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_SELECTED_BON:
      return {
        ...state,
        selectedBon: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_CASH_TEMP:
      return {
        ...state,
        tampungCash: action.payload.data,
        sumTunai: action.payload.sumTunai,
        sumNetto: action.payload.sumTunaiNetto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_CASH_USD_TEMP:
      return {
        ...state,
        tampungCashUSD: action.payload.data,
        sumTunai: action.payload.sumTunai,
        sumNetto: action.payload.sumTunaiNetto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_TRANSFER_TEMP:
      return {
        ...state,
        tampungTransfer: action.payload.data,
        sumTransfer: action.payload.sumTransfer,
        sumTransferNetto: action.payload.sumTransferNetto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case SET_GIRO_TEMP:
      return {
        ...state,
        tampungGiro: action.payload.data,
        sumGiro: action.payload.sumGiro,
        sumGiroNetto: action.payload.sumGiroNetto,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case GET_TABLE_TOLAKAN_CUSTOMER:
      return {
        ...state,
        tabelTolakanCustomer: action.payload.data,
        alert: action.payload.alert,
        error: action.payload.error,
      };
    case TOTAL_BON:
      return {
        ...state,
        totalBon: action.payload.data,
      };
    case GET_DATA_USER:
      return {
        ...state,
        editUser: action.payload,
      };
    case EDIT_VALIDASI:
      return {
        ...state,
        editParameterValidasi: action.payload,
      };
    case GET_LIST_CETAK_ULANG:
      return {
        ...state,
        listCetak: action.payload.items,
      };
    case GET_DATA_MUTASI_BARANG:
      return {
        ...state,
        mutasiBarang: action.payload.data,
      };
    case GET_DATA_PINDAH_BARANG:
      return {
        ...state,
        pindahBarang: action.payload.data,
      };
    case EDIT_PINDAH_BARANG:
      return {
        ...state,
        editPindahBarang: action.payload.data,
      };
    case GET_DATA_MUTASI_CIOK:
      return {
        ...state,
        mutasiCiok: action.payload.data,
      };
    case GET_DATA_KIRIM_MASAK:
      return {
        ...state,
        kirimMasak: action.payload.data,
      };
    case GET_DATA_KIRIM_BAHAN:
      return {
        ...state,
        kirimBahan: action.payload.data,
      };
    case GET_DATA_RETURN_SUPPLIER:
      return {
        ...state,
        returnSupplier: action.payload.data,
      };
    case GET_DATA_PENGAMBILAN_BARCODE:
      return {
        ...state,
        pengambilanBarcode: action.payload.data,
      };
    case GET_DATA_CLOSE_PENGAMBILAN_BARCODE:
      return {
        ...state,
        closePengambilanBarcode: action.payload.data,
      };
    case GET_DATA_TIMBANG_BARANG:
      return {
        ...state,
        timbangBarang: action.payload.data,
      };
    case GET_DATA_TRANSAKSI_PENJUALAN:
      return {
        ...state,
        transaksiPenjualan: action.payload.data,
      };
    case GET_DATA_RETURN_PENJUALAN:
      return {
        ...state,
        returnPenjualan: action.payload.data,
      };
    case GET_TABEL_TOTALAN_TIMBANGAN:
      return {
        ...state,
        totalanTimbangan: action.payload.data,
      };
    case GET_TABLE_TERIMA_MASAK:
      return {
        ...state,
        listTerimaMasak: action.payload.data,
      };
    case GET_LIST_TUKANG:
      return {
        ...state,
        listTukang: action.payload.data,
      };
    case GET_VALIDASI_BARANG_KIRIM_MASAK:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_VALIDASI_BARANG_TERIMA_MASAK:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_VALIDASI_BARANG_MUTASI_BARANG:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_VALIDASI_BARANG_MUTASI_LK:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_VALIDASI_BARANG_PINDAH_BARANG:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_PEMBAYARAN_HUTANG_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_PENERIMAAN_BARANG_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_RETURN_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_TITIP_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_TOLAKAN_PEBAYARAN_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_VALIDASI_SUPPLIER_TOLAKAN_RETURN_SUPPLIER:
      return {
        ...state,
        listValidasiSupplier: action.payload.data,
      };
    case GET_DATA_SELECTED_VALIDASI_SUPPLIER:
      return {
        ...state,
        listDataSelectedValidasiSupplier: action.payload.data,
      };
    case GET_DATA_SELECTED_PEMBAYARAN_CUSTOMER:
      return {
        ...state,
        listDataSelectedPembayaranCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_PEMBAYARAN_PERBON:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_PENJUALAN:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_PENJUALAN_24K:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_RETURN_PENJUALAN:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_TITIP_GR_CUSTOMER:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_TOLAK_PEMBAYARAN_CUSTOMER:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_PEMBAYARAN_LM:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_PEMBELIAN_LM:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_CORRECTION_NOTE:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_DATA_EDIT_PINDAH_BARANG:
      return {
        ...state,
        listEditPindahBarang: action.payload,
      };
    case GET_DATA_TRANSAKSAI_PENJUALAN_LM:
      return {
        ...state,
        listDataTransaksiPenjualanLM: action.payload.data,
        jumlahTransaksiPenjualanLM: action.payload.jumlah,
        totalTransaksiPenjualanLM: action.payload.total,
      };
    case GET_DATA_LIST_PENJUALAN_LM:
      return {
        ...state,
        listDataTablePenjualanLM: action.payload.data,
      };
    case GET_DATA_LIST_PEMBELIAN_LM:
      return {
        ...state,
        listDataTablePembelianLM: action.payload.data,
      };
    case GET_DATA_LIST_SUM_PEMBAYARAN_LM:
      return {
        ...state,
        sumTotalBayar: action.payload.data,
      };
    case GET_DATA_SALDO_AWAL_PIUTANG_CUSTOMER:
      return {
        ...state,
        dataSaldoAwalPiutangCustomer: action.payload.data,
      };
    case GET_DATA_SALDO_AWAL_STOK:
      return {
        ...state,
        dataSaldoAwalStok: action.payload.data,
      };
    case GET_MENU_LIST:
      return {
        ...state,
        listMenu: action.payload.data,
      };
    case GET_TABEL_TOTALAN_BARCODE:
      return {
        ...state,
        listTableTotalanBarcode: action.payload.data,
      };
    case GET_DATA_REKAP_BON_CUSTOMER:
      return {
        ...state,
        listDataRekapBon: action.payload.data,
      };
    case GET_DATA_CLOST_TRANSAKSI_PENJUALAN:
      return {
        ...state,
        listCloseTransaksiPenjualan: action.payload.data,
      };
    case GET_DATA_CLOSE_TRANSAKSI_PENJUALAN_SELECTED:
      return {
        ...state,
        listCloseTransaksiPenjualanSelected: action.payload.data,
      };
    case GET_LIST_DATA_USER:
      return {
        ...state,
        listDataUser: action.payload.data,
      };
    case GET_TOTAL_NW:
      return {
        ...state,
        totalNW: action.payload.data,
      };
    case FINISH_SEND:
      return {
        ...state,
        onSend: action.payload.data,
      };
    case PROGRESS_SEND:
      return {
        ...state,
        onSend: action.payload.data,
      };
    case SET_LOGIN:
      return {
        ...state,
        username: action.payload.username,
        level: action.payload.level,
      };
    case GET_IS_LOGIN:
      return {
        ...state,
        username: action.payload.username,
        level: action.payload.level,
      };
    case SET_TIPE_BAYAR_MODAL:
      return {
        ...state,
        tipeBayarModal: action.payload.data,
      };
    case SHOW_GUIDE_TOUR:
      return {
        ...state,
        showTourGuide: action.payload.data,
      };
    case HIDE_GUIDE_TOUR:
      return {
        ...state,
        showTourGuide: action.payload.data,
      };
    case GET_HUTANG_SUPPLIER:
      return {
        ...state,
        dataHutangSupplier: action.payload.data,
      };
    case GET_LOKASI_PINDAH:
      return {
        ...state,
        dataLokasiPindah: action.payload.data,
      };
    case GET_LIST_VALIDASI_TRANSFER_CUSTOMER:
      return {
        ...state,
        listValidasiTransfer: action.payload.data,
      };
    case GET_DATA_SELECTED_VALIDASI_TRANSFER:
      return {
        ...state,
        listValidasiTransferSelected: action.payload.data,
      };
    case GET_LIST_VALIDASI_PEMBBAYARAN_GIRO:
      return {
        ...state,
        listValidasiGiro: action.payload.data,
      };
    case GET_DATA_SELECTED_VALIDASI_GIRO:
      return {
        ...state,
        listValidasiGiroSelected: action.payload.data,
      };
    case GET_LIST_CLOSE_BARCODE:
      return {
        ...state,
        listCloseBarcode: action.payload.data,
      };
    case GET_CLOSE_PENGAMBILAN_BARCODE_SELECTED:
      return {
        ...state,
        listCloseBarcodeSelected: action.payload.data,
        no_barcode: action.payload.no_barcode,
      };
    case GET_LIST_TIMBANGAN:
      return {
        ...state,
        listCloseTimbangan: action.payload.data,
      };
    case GET_LIST_TIMBANGAN_SELECTED:
      return {
        ...state,
        listCloseTimbanganSelected: action.payload.data,
        no_timbang: action.payload.no_barcode,
      };
    case GET_DATA_TOLAKAN_HUTANG_SUPPLIER:
      return {
        ...state,
        listTolakanHutangSupplier: action.payload.data,
      };
    case GET_DATA_TOLAKAN_RETURN_SUPPLIER:
      return {
        ...state,
        listTolakanReturnSupplier: action.payload.data,
      };
    case GET_DATA_TOLAKAN_PEMBAYARAN_CUSTOMER:
      return {
        ...state,
        listTolakanPembayaranCustomer: action.payload.data,
      };
    case GET_DATA_TOLAKAN_RETURN_CUSTOMER:
      return {
        ...state,
        listTolakanReturnCustomer: action.payload.data,
      };
    case EDIT_KELOMPOK:
      return {
        ...state,
        editKelompok: action.payload.data,
      };
    case GET_LIST_PIUTANG_CUSTOMER:
      return {
        ...state,
        listPiutangCustomer: action.payload.data,
      };
    case GET_VALIDASI_CUSTOMER_TRANSFER_BALANCE:
      return {
        ...state,
        listValidasiCustomer: action.payload.data,
      };
    case GET_STATUS_TOTALAN:
      return {
        ...state,
        status_totalan: action.payload.data,
      };
    case GET_SELISIH_TOTALAN:
      return {
        ...state,
        selisih_totalan: action.payload.data,
      };
    case GET_VALIDASI_PO:
      return {
        ...state,
        validasiPO: action.payload.data,
      };
    case GET_VALIDASI_PO_SELECTED:
      return {
        ...state,
        validasiPOSelected: action.payload.data,
      };
    case GET_LIST_PO:
      return {
        ...state,
        listPO: action.payload.data,
      };
    case SET_DATA_PO:
      return {
        ...state,
        dataPO: action.payload.data,
      };
    case GET_DATA_BARANG_PACKING:
      return {
        ...state,
        packingList: action.payload.data,
      };
    case GET_LIST_PO_PACKING:
      return {
        ...state,
        listPOPacking: action.payload.data,
      };
    case GET_DATA_BARANG_PACKING_S:
      return {
        ...state,
        listPackingS: action.payload.data,
      };
    case GET_DATA_BARANG_PACKING_M:
      return {
        ...state,
        listPackingM: action.payload.data,
      };
    case GET_LIST_PACKING:
      return {
        ...state,
        listPacking: action.payload.data,
      };
    case GET_LIST_PACKING_PENJUALAN:
      return {
        ...state,
        listPacking: action.payload.data,
      };
    case GET_CAMERA_URI:
      return {
        ...state,
        cameraURI: action.payload.data,
      };
    case SET_DATA_GAMBAR_S:
      return {
        ...state,
        listGambarS: action.payload.data,
      };
    case SET_DATA_GAMBAR_M:
      return {
        ...state,
        listGambarM: action.payload.data,
      };
    case SET_DATA_GAMBAR_L:
      return {
        ...state,
        listGambarL: action.payload.data,
      };
    case GET_KELOMPOK_HARGA:
      return {
        ...state,
        kelompokHarga: action.payload.data,
      };
    case GET_LIST_PO_PENJUALAN:
      return {
        ...state,
        listPO: action.payload.data,
      };
    case GET_DATA_BARANG_SET:
      return {
        ...state,
        listBarangSet: action.payload.data,
      };
    case GET_BARANG_SET_OPEN:
      return {
        ...state,
        BarangSet: action.payload.data,
      };
    case GET_SALDO_BARANG:
      return {
        ...state,
        SaldoBarang: action.payload.data,
      };
    case VALIDATE_OUTSTAND:
      return {
        ...state,
        ValidateOutstand: action.payload.data,
      };
    case GET_DATA_TRANSKASI_REPARASI:
      return {
        ...state,
        listReparasi: action.payload.data,
      };
    case GET_LIST_VALIDASI_REPARASI:
      return {
        ...state,
        ValidasiReparasi: action.payload.data,
      };
    case GET_LIST_VALIDASI_REPARASI_SELECTED:
      return {
        ...state,
        ValidasiReparasiSelected: action.payload.data,
      };
    case GET_LIST_SELESAI_REPARASI:
      return {
        ...state,
        ListSelesaiReparasi: action.payload.data,
      };
    case GET_LIST_SERAH_REPARASI:
      return {
        ...state,
        ListSerahReparasi: action.payload.data,
      };
    case GET_LIST_TIMBANGAN_STOCK:
      return {
        ...state,
        ListTimbangStock: action.payload.data,
      };
    case KIRIM_STOCK_PUSAT:
      return {
        ...state,
        ListKirimStockPusat: action.payload.data,
      };
    case GET_DATA_RETURN_PENJUALAN_NON_TOKO:
      return {
        ...state,
        ListReturnPenjualanNonToko: action.payload.data,
      };
    case GET_DATA_TIMBANG_STOCK:
      return {
        ...state,
        totalTimbangStock: action.payload.data,
      };
    case GET_KODE_AKSESORIS_LIST:
      return {
        ...state,
        ListAksesoris: action.payload.data,
      };
    case EDIT_MODEL:
      return {
        ...state,
        EditModel: action.payload,
      };
    case GET_KODE_BARANG_MASTER:
      return {
        ...state,
        ListModelMaster: action.payload.data,
      };
    case SET_TOTAL_NETTO_SUPPLIER:
      return {
        ...state,
        total_netto_supplier: action.payload,
      };
    case SET_TOTAL_RP_SUPPLIER:
      return {
        ...state,
        total_rp_supplier: action.payload,
      };
    case GET_JENIS_LABEL:
      return {
        ...state,
        jenis_label: action.payload.jenis_label,
      };
    case GET_DATA_STOCK:
      return {
        ...state,
        data_stock: action.payload.data,
        ListTimbangStock: action.payload.data,
      };
    case GET_VALIDASI_KIRIM_STOCK:
      return {
        ...state,
        listValidasiBarang: action.payload.data,
      };
    case GET_BANK_LIST:
      return {
        ...state,
        bank: action.payload.data,
      };
    case EDIT_BANK_LIST:
      return {
        ...state,
        editDataBank: action.payload,
      };
    case GET_CUSTOMER_INDUK_LIST:
      return {
        ...state,
        customerInduk: action.payload.data,
      };
    case GET_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload.data,
      };
    case GET_KODE_BARANG_CIOK:
      return {
        ...state,
        barangciok: action.payload.data,
      };
    case GET_LIST_PEMGAMBILAN_CIOK:
      return {
        ...state,
        listPengambilanCiok: action.payload.data,
      };
    case GET_REPARASI_LIST:
      return {
        ...state,
        reparasi: action.payload.data,
      };
    case EDIT_REPARASI:
      return {
        ...state,
        editReparasi: action.payload,
      };
    case GET_PARAMETER_REPARASI:
      return {
        ...state,
        ParameterReparasi: action.payload.data,
      };
    case GET_LIST_REPARASI_BARANG:
      return {
        ...state,
        reparasi_barang: action.payload.data,
      };
    case GET_ANAK_CUSTOMER:
      return {
        ...state,
        anak_customer: action.payload.data,
      };
    case SAVE_DUPLIKAT_DATA:
      return {
        ...state,
        duplikat_data: action.payload.data,
      };
    case SET_DUPLIKAT_REPARASI:
      return {
        ...state,
        duplikat_reparasi: action.payload.data,
      };
    case GET_ALL_GROUP:
      return {
        ...state,
        listGroup: action.payload.data,
      };
    case GET_ALL_KADAR:
      return {
        ...state,
        kadar: action.payload.data,
      };
    case SET_EDIT_CABANG_SUPPLIER:
      return {
        ...state,
        editCabangSupplier: action.payload,
      };
    case GET_ALL_CABANG_SUPPLIER:
      return {
        ...state,
        dataCabangSupplier: action.payload,
      };
    case GET_LIST_UNVALID_TERIMA_BARANG:
      return {
        ...state,
        listUnvalidTerimaBarang: action.payload,
      };
    case SET_EDIT_TERIMA_SUPPLIER:
      return {
        ...state,
        editTerimaSupplier: action.payload,
      };
    case SAVE_PREV_NO_TERIMA:
      return {
        ...state,
        prev_no_terima: action.payload,
      };
    case GET_UNVALID_RETUR_SUPPLIER:
      return {
        ...state,
        listUnvalidReturSupplier: action.payload,
      };
    case GET_UNVALID_RETUR_CUSTOMER:
      return {
        ...state,
        listUnvalidReturCustomer: action.payload,
      };
    case GET_UNVALID_PENJUALAN:
      return {
        ...state,
        listUnvalidPenjualan: action.payload,
      };
    case SET_EDIT_PENJUALAN:
      return {
        ...state,
        editPenjualanData: action.payload,
      };
    case SET_EDIT_RETUR_PENJUALAN:
      return {
        ...state,
        editReturPenjualanData: action.payload,
      };
    case CHANGE_EDIT_BON_PENJUALAN:
      return {
        ...state,
        isEditBon: action.payload,
      };
    case CHANGE_EDIT_BON_RETURN_PENJUALAN:
      return {
        ...state,
        isEditBoReturn: action.payload,
      };
    case SAVE_PREV_NO_PENJUALAN:
      return {
        ...state,
        prev_no_penjualan: action.payload,
      };
    case SAVE_PREV_NO_RETURN_PENJUALAN:
      return {
        ...state,
        prev_no_return_penjualan: action.payload,
      };
    case SET_SELECTED_REKAP_BON:
      return {
        ...state,
        selectedRekapBon: action.payload,
      };
    case GET_LOKASI_MIX:
      return {
        ...state,
        dataLokasiPindah: action.payload.data,
      };
    case GET_SALDO_BARANG_REKAP:
      return {
        ...state,
        saldoBarangRekap: action.payload.data,
      };
    case SET_EDIT_PENERIMAAN_SUPP:
      return {
        ...state,
        isEditTerimaSupp: action.payload.data === "true",
      };
    case SET_EDIT_TRANSAKSI_PENJUALAN:
      return {
        ...state,
        isEditTransaksiPenjualan: action.payload.data === "true",
      };
    case SET_EDIT_TRANSAKSI_RETURN_PENJUALAN:
      return {
        ...state,
        isEditTransaksiReturnPenjualan: action.payload.data === "true",
      };
    default:
      return state;
  }
};

export default provinsi;
