import {
  AxiosMasterDelete,
  AxiosMasterGet,
  AxiosMasterPost,
  AxiosMasterPut,
} from "../../../Axios";
import { CustomerModel } from "../../../Models/CustomerModel";
import { finishSend, progressSend } from "../../../actions/LogicAction";
import { toast } from "react-toastify";
import { ToastKirim } from "../../../actions/toast";
import { hideModal } from "../../../actions/ModalAction";
import { gagal, reactive } from "../../../Alert";
import { reset } from "redux-form";
import Swal from "sweetalert2";
import { purifiedData } from "../../../helper";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_INDUK_LIST = "GET_CUSTOMER_INDUK_LIST";
export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";
export const GET_ANAK_CUSTOMER = "GET_ANAK_CUSTOMER";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const SET_SELECTED_BON = "SET_SELECTED_BON";
export const SET_CASH_TEMP = "SET_CASH_TEMP";
export const SET_CASH_USD_TEMP = "SET_CASH_USD_TEMP";
export const SET_TRANSFER_TEMP = "SET_TRANSFER_TEMP";
export const SET_GIRO_TEMP = "SET_GIRO_TEMP";

export const getcustomerList = () => {
  return (dispatch) => {
    AxiosMasterGet("customer/get/all")
      .then((res) =>
        dispatch({
          type: GET_CUSTOMER_LIST,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_CUSTOMER_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getcustomerInduk = (data) => {
  return (dispatch) => {
    AxiosMasterGet("customer/get/by-kode-customer/" + data)
      .then((res) =>
        dispatch({
          type: GET_CUSTOMER_INDUK_LIST,
          payload: {
            data: res.data.customer_induk,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_CUSTOMER_INDUK_LIST,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const getcustomerInfo = (data) => {
  return (dispatch) => {
    AxiosMasterGet("customer/get/by-kode-customer/" + data)
      .then((res) =>
        dispatch({
          type: GET_CUSTOMER_INFO,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_CUSTOMER_INFO,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};
export const getAnakCustomer = (data) => {
  return (dispatch) => {
    AxiosMasterGet("customer/anak/" + data)
      .then((res) =>
        dispatch({
          type: GET_ANAK_CUSTOMER,
          payload: {
            data: res.data,
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
            error: false,
          },
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_ANAK_CUSTOMER,
          payload: {
            data: [],
            alert:
              "alert alert-danger alert-dismissible bg-danger text-white border-0 fade show",
            error: true,
          },
        })
      );
  };
};

export const editcustomer = (
  kodeCustomer,
  alamat,
  email,
  handPhone,
  kodeArea,
  kodeExt,
  kodeKota,
  namaCustomer,
  namaToko,
  tanggalDaftar,
  telepon,
  fax,
  plafon,
  tempo_kredit,
  rekening_tujuan,
  grade_potensi,
  keterangan
) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_CUSTOMER,
      payload: {
        kodeCustomer: kodeCustomer,
        alamat: alamat,
        email: email,
        handPhone: handPhone,
        kodeArea: kodeArea,
        kodeExt: kodeExt,
        kodeKota: kodeKota,
        namaCustomer: namaCustomer,
        namaToko: namaToko,
        tanggalDaftar: tanggalDaftar,
        telepon: telepon,
        fax: fax,
        plafon: plafon,
        tempo_kredit: tempo_kredit,
        rekening_tujuan: rekening_tujuan,
        grade_potensi: grade_potensi,
        keterangan: keterangan,
      },
    });
  };
};

export const setSelectedBon = () => {
  var total = JSON.parse(localStorage.getItem("selectedData"))
    ? JSON.parse(localStorage.getItem("selectedData"))
    : "kosong";
  var hasil = [{ tanggal: "Kosong" }];
  if (total === "kosong") {
    hasil = [{ tanggal: "Kosong" }];
  } else {
    hasil = total;
  }
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_BON,
      payload: {
        data: hasil,
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setCashTemp = () => {
  var total = JSON.parse(localStorage.getItem("cashTemp"))
    ? JSON.parse(localStorage.getItem("cashTemp"))
    : "kosong";
  var hasil = [{ tanggal: "Kosong" }];
  if (total === "kosong") {
    hasil = [];
  } else {
    hasil = total;
    var sumTunai = total
      .map((d) => parseFloat(d.tunaiRp))
      .reduce((acc, hasil) => acc + hasil);
    var sumNetto = total
      .map((d) => parseFloat(d.tunaiNetto))
      .reduce((acc, hasil) => acc + hasil);
    localStorage.setItem("TotalTunai", sumNetto);
  }
  return (dispatch) => {
    dispatch({
      type: SET_CASH_TEMP,
      payload: {
        data: hasil,
        sumTunai: parseFloat(sumTunai),
        sumTunaiNetto: parseFloat(sumNetto),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setCashUSDTemporary = () => {
  const total = JSON.parse(localStorage.getItem("cashUSDTemporary"))
    ? JSON.parse(localStorage.getItem("cashUSDTemporary"))
    : "kosong";

  let hasil = [{ tanggal: "Kosong" }];
  let sumTunai = 0;
  let sumNetto = 0;

  if (total === "kosong") {
    hasil = [];
  } else {
    hasil = total;
    sumTunai = total
      .map((d) => parseFloat(d.tunaiRp))
      .reduce((acc, hasil) => acc + hasil);
    sumNetto = total
      .map((d) => parseFloat(d.tunaiNetto))
      .reduce((acc, hasil) => acc + hasil);

    localStorage.setItem("totalTunaiUSD", sumNetto);
  }

  return (dispatch) => {
    dispatch({
      type: SET_CASH_USD_TEMP,
      payload: {
        data: hasil,
        sumTunai: parseFloat(sumTunai),
        sumTunaiNetto: parseFloat(sumNetto),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setTransferTemp = () => {
  var total = JSON.parse(localStorage.getItem("transferTemp"))
    ? JSON.parse(localStorage.getItem("transferTemp"))
    : "kosong";
  var hasil = [{ tanggal: "Kosong" }];
  if (total === "kosong") {
    hasil = [];
  } else {
    hasil = total;
    var filtered = total.filter((a) => a.transferRp > 0);
    var sumTunai = filtered
      .map((d) => parseFloat(d.transferRp))
      .reduce((acc, hasil) => acc + hasil);
    var sumNetto = filtered
      .map((d) => parseFloat(d.transferNetto))
      .reduce((acc, hasil) => acc + hasil);
    localStorage.setItem("totalTransfer", sumNetto);
  }
  return (dispatch) => {
    dispatch({
      type: SET_TRANSFER_TEMP,
      payload: {
        data: hasil,
        sumTransfer: parseFloat(sumTunai),
        sumTransferNetto: parseFloat(sumNetto),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const setGiroTemp = () => {
  var total = JSON.parse(localStorage.getItem("giroTemp"))
    ? JSON.parse(localStorage.getItem("giroTemp"))
    : "kosong";
  var hasil = [{ tanggal: "Kosong" }];
  if (total === "kosong") {
    hasil = [];
  } else {
    hasil = total;
    var filtered = total.filter((a) => a.giroRp > 0);
    var sumTunai = filtered
      .map((d) => parseFloat(d.giroRp))
      .reduce((acc, hasil) => acc + hasil);
    var sumNetto = filtered
      .map((d) => parseFloat(d.giroNetto))
      .reduce((acc, hasil) => acc + hasil);
    localStorage.setItem("totalGiro", sumNetto);
  }
  return (dispatch) => {
    dispatch({
      type: SET_GIRO_TEMP,
      payload: {
        data: hasil,
        sumGiro: parseFloat(sumTunai),
        sumGiroNetto: parseFloat(sumNetto),
        alert:
          "alert alert-danger alert-dismissible bg-danger text-white border-0 fade d-none",
        error: false,
      },
    });
  };
};

export const sendCustomer = (hasil) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let data = new CustomerModel(
      hasil.tanggalDaftar,
      hasil.namaCustomer,
      hasil.namaToko,
      hasil.kodeExt,
      hasil.kodeKota,
      hasil.kodeKecamatan,
      hasil.alamat,
      hasil.telepon,
      hasil.handPhone,
      hasil.fax,
      hasil.email,
      hasil.plafon,
      hasil.tempo_kredit,
      hasil.rekening_tujuan,
      hasil.grade_potensi,
      hasil.keterangan
    );
    purifiedData(data);
    try {
      await toast.promise(
        AxiosMasterPost("customer/add", data.post()),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getcustomerList());
      dispatch(reset("Customer"));
    } catch (err) {
      let error = err.response.data;
      let check = error.includes("deleted");
      check
        ? reactive(
            err,
            hasil.kodeCustomer,
            "customer/reactive/",
            data,
            "customer/edit/"
          ).then(() => dispatch(getcustomerList()))
        : gagal("Data Gagal Ditambahkan");
      dispatch(getcustomerList());
    }
  };
};

export const sendEditCustomer = (hasil) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    let data = new CustomerModel(
      hasil.tanggalDaftar,
      hasil.namaCustomer,
      hasil.namaToko,
      hasil.kodeExt,
      hasil.kodeKota,
      hasil.kodeKecamatan,
      hasil.alamat,
      hasil.telepon,
      hasil.handPhone,
      hasil.fax,
      hasil.email,
      hasil.plafon,
      hasil.tempo_kredit,
      hasil.rekening_tujuan,
      hasil.grade_potensi,
      hasil.keterangan
    );
    purifiedData(data);
    try {
      await toast.promise(
        AxiosMasterPut("customer/edit/" + hasil.kodeCustomer, data.post()),
        ToastKirim
      );
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getcustomerList());
      dispatch(reset("Customer"));
    } catch (err) {
      dispatch(finishSend());
      dispatch(hideModal());
      dispatch(getcustomerList());
    }
  };
};

export const deleteCustomer = (kode) => {
  return async (dispatch, getState) => {
    dispatch(progressSend());
    Swal.fire({
      title: "Anda Yakin ?",
      text: "Anda Akan Menghapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          dispatch(progressSend());
          await toast.promise(
            AxiosMasterDelete("customer/delete/" + kode),
            ToastKirim
          );
          dispatch(getcustomerList());
          dispatch(finishSend());
        } catch (e) {
          gagal("Koneksi Bermasalah, Silahkan Coba Beberapa Saat Lagi..");
          dispatch(finishSend());
        }
      }
    });
    dispatch(finishSend());
  };
};
